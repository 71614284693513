.home__hero-link {
    color: var(--var-white);
    font-size: 1.3rem;
    width: 16rem;
}

.icon-process__gif {
    width: 100%;
    transform: scale(1);
}

@media screen and (min-width: 1000px) {
    .icon-process__gif {
        transform: scale(1.2);
    }
}

@media screen and (min-width: 1100px) {
    .icon-process__gif {
        transform: scale(1.3);
    }
}

/* Features */
.features {
    margin-top: 4rem;
    background: var(--var-background-dark);
    width: 100%;
    padding: 2rem 0;
}

.features-p {
    border-bottom: 1px solid var(--var-light);
    padding-bottom: 2rem;
}

.features__list-item {
    font-size: 1.1rem;
}

/* Showcase */
.showcase {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.showcase__container {
    gap: .5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.showcase__item {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

@media screen and (min-width: 1000px) {
    .showcase__item:last-child {
        display: block;
    }

    .showcase__container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        padding: 2rem;
        gap: 1rem;
    }
}

/* C-T-A (call to action) */
.cta {
    margin-top: 4rem;
    background: var(--var-background-dark);
    width: 100%;
}

.cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 4rem 2rem;
}

.cta__header {
    text-align: left;
    margin-bottom: 2rem;
    font-size: 1.5rem;
}

.cta__btn {
    margin-top: 1rem;
    width: 16rem;
    font-size: 1.3rem;
}

@media screen and (min-width: 1000px) {
    .cta-container {
        padding: 4rem 2rem;
        align-items: center;

    }

    .cta__header {
        font-size: 1.5rem;
        text-align: center;
    }

    .cta__btn {
        width: 20rem;
        font-size: 1.5rem;
    }
}

/* Pricing */
.pricing__header {
    margin-top: 5rem;
    text-align: left;
    font-size: 1.6rem;
    width: 100%;
}

.pricing__container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
}

.pricing-card {
    width: 100%;
    padding: 2rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 100%;
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
}

.pricing-card:hover:not(.pricing-card--add) {
    scale: 1.03;
    cursor: default;
}

.pricing-card__header {
    text-align: left;
    width: 100%;
    font-size: 1.5rem;
}

.pricing-card__price {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--var-primary)
}

.pricing-card__list {
    width: 100%;
    margin-top: 1rem;
}

.pricing-card__list-icon {
    color: var(--var-primary);
    margin-right: .5rem;
    font-size: 1rem;
}

.pricing-card__list-item {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.pricing-card__add-btn {
    width: 100%;
    font-weight: 600;
}

.pricing-card__add-btn:hover {
    color: var(--var-white);
}

.pricing-card__header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pricing-card__header-discount {
    background: var(--var-primary);
    color: var(--var-white);
    padding: 0.5rem;
    position: relative;
    top: -32px;
    right: -32px;
    border-radius: 9px;
    font-weight: 700;
}

.pricing-card__badge {
    display: none;
    max-width: 14rem;
}

.pricing-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing-card__body {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .pricing-card__badge {
        display: block;
    }

    .pricing-card__body {
        width: auto;
    }
}


@media screen and (min-width: 1000px) {
    .pricing-card__badge {
        display: none;
    }

    .pricing-card__body {
        width: 100%;
    }

    .pricing__header {
        font-size: 1.8rem;
        text-align: center;
    }

    .pricing__container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .pricing__card-price {
        font-size: 1.8rem;
    }

    .pricing-card__list-item {
        font-size: 1.1rem;
    }

    .pricing-card__header {
        font-size: 1.7rem;
    }
}

/* FAQ */
.faq {
    margin-top: 4rem;
    width: 100%;
    padding: 3rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq__header {
    text-align: left;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    width: 100%;
}

.accordian-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.accordian-item-header:hover {
    cursor: pointer;
}

.accordian-item-p {
    font-size: 1.1rem;
}

.faq-accordian__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    text-align: left;
}

.accordian-item {
    width: 100%;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--var-light);
}

.accordian-item__header {
    padding: 1rem 0rem;
    font-size: 1.1rem;
    font-weight: 600;
    display: inline;
}

@media screen and (min-width: 1000px) {
    .faq {
        padding: 4rem 2rem;
    }

    .faq__header {
        font-size: 1.8rem;
    }

    .faq-accordian__container {
        padding: 0;
        width: 70%;
    }

    .faq__header {
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.8rem;
    }

    .accordian-item-p {
        font-size: 1.1rem;
    }

    .accordian-item__header {
        font-size: 1.3rem;
    }
}