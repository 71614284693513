.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem;
    background: var(--var-light) !important;
}

.bg-body-tertiary {
    background-color: var(--var-background) !important;
}

.nav-link {
    transition: all .3s;
}

#navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
}

.navbar-brand {
    font-size: 1.4rem;
    font-weight: 700;
}

.container-fluid {
    padding: 0;
    max-width: 1345px;
}

.navbar-toggler {
    border: none;
    outline: none;
}

.navbar-toggler:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.navbar-toggler-icon {
    font-size: .8rem;
}

.navbar-nav {
    justify-content: flex-end;
    width: 90%;
}

.nav-item {
    border-bottom: 1px solid var(--var-light);
}

.nav-item:last-child {
    border-bottom: none;
}

.nav-link-important {
    color: var(--var-primary);
    font-weight: 600;
}

@media screen and (min-width: 1000px) {
    .nav-item {
        border-bottom: none;
    }

    .header {
        margin-bottom: 10rem;
    }

    #navbar {
        padding: 1rem 2rem;
    }

    .navbar-brand {
        padding: 0 0 0 2rem;
    }
}