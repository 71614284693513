.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.footer-item__link {
    color: var(--var-text);
    font-size: 1.2rem;
    font-weight: 400;
}

.footer-item__link-main {
    font-weight: 700;
}

.footer-item {
    margin-bottom: .3rem;
}

@media screen and (min-width: 1000px) {
    .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        padding: 2rem;

    }
}