.icon-generator__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.icon-generator__header {
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
    margin: 0;
}

.icon-generator__container-p {
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
}

.icon-generator__container__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;
}

.icon-generator__container__form-input {
    margin: .5rem 0 0 0;
    width: 100%;
    font-weight: 600;
    color: var(--color-text);
    background: var(--var-background);
}

.icon-generator__container__form-btn {
    font-weight: 700;
    text-transform: uppercase;
    width: 100% !important;
    border-radius: 9px;
    margin: .5rem 0 0 0 !important;
}

.icon-generator__container__form-btn:hover {
    transform: none !important;
}

.icon-generator__container-info {
    width: 100%;
    text-align: left;
    font-size: .95rem;
    margin-top: .3rem;
}

/* Tips / Rules for users */
.icon-generator__rules-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.icon-generator__rules {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    margin: 0;
}

.icon-generator__rules-header {
    font-weight: 700;
    font-size: 1.2rem;
    margin: 0 0 .5rem 0;
}

.icon-generator__rules-item {
    margin: 0 0 .3rem 0;
    padding: 0;
    list-style: none;
    font-size: .95rem;
    font-weight: 500;
}

.icon-generator__icon-display__icon-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.icon-generator__samples {
    width: 100%;
}

.icon-generator__samples-header {
    margin: 2rem 0 0 0;
    font-size: 1.6rem;

}

@media screen and (min-width: 768px) {

    .icon-generator__container__form-btn {
        width: 12rem;
        margin-left: .5rem;
        margin-bottom: .6rem;
    }

    .icon-generator__container__form-input {
        margin: .3rem 0 0 0;
    }

    .icon-generator__header {
        font-size: 1.8rem;
    }

    .icon-generator__container-info {
        font-size: 1rem;
    }

    .icon-generator__rules-item {
        font-size: 1.1rem;
    }

    .icon-generator__rules-header {
        font-size: 1.5rem;
    }
}

/* Icon Generator Step 2 */
.icon-generator__icon-display {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding: 1rem 0 2rem 0;
}

.icon-generator__icon-display__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.icon-generator__icon-display__icon-img {
    border-radius: 9px;
    object-fit: cover;
}

.icon-generator__icon-display__icon-img:hover {
    cursor: pointer;
}

.icon-generator__icon-display__icon-img--sample:hover {
    cursor: default;
}

@media screen and (min-width: 1000px) {
    .icon-generator__icon-display {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .icon-generator__container-p {
        font-size: 1.3rem;
    }
}