.my-icons {
    min-height: 100vh;
}

.my-icons__container {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    gap: 1rem;
    padding: 0rem !important;
    margin-top: 2rem;
}

.my-icons__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    width: 100%;
}

.my-icons__icon {
    width: 100%;
    border-radius: 9px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.my-icons__icon:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.my-icons__header {
    text-align: left;
    width: 100%;
}

.my-icons__desc {
    font-weight: 500;
}

.my-icons__header-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
}

.my-icons__operations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.my-icons__operations-btn {
    width: 100%;
    margin-top: .5rem;
}

.my-icons__icon--shade {
    position: relative;
    width: 100%;
}

.my-icons__icon--shade:hover {
    cursor: pointer;
}

.my-icons__icon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(70%);
}

.my-icons__icon-trash {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 2rem;
    transform: translate(-50%, -50%);
}

.my-icons__no-icons {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 3rem;
}

@media screen and (min-width: 1000px) {
    .my-icons__container {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 1rem;
    }

    .my-icons__header-container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}