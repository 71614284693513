.profile {
    min-height: 100vh;
}

.profile__card {
    background: var(--var-light);
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    width: 100%;
    max-width: 50rem;
}

.profile__card-header {
    font-weight: 700;
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
}

.profile__btn {
    width: 100%;
    margin-top: .5rem;
}

.profile__card-text {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    margin: .1rem 0 0 0;
}

.profile__card-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile__card-group__first-child {
    margin-right: 0;
    width: 100%;
    margin-top: 1rem;
}

.profile__card-group__last-child {
    margin-left: 0;
    width: 100%;
    margin-top: 1rem;
}

.profile__btn:disabled {
    background: var(--var-primary);
}

@media screen and (min-width: 1000px) {
    .profile__card-group {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .profile__card-group__first-child {
        margin-right: .5rem;
    }

    .profile__card-group__last-child {
        margin-left: .5rem;
    }

    .profile__card-group__first-child,
    .profile__card-group__last-child {
        margin-top: 0;
    }
}