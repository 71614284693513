.add-credits__header {
    font-weight: 700;
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
    margin: 1rem 0;
}

.add-credits__p {
    text-align: left;
    margin: 0;
    font-size: 1rem;
    text-align: center;
    padding: 0 1rem;
}


@media screen and (min-width: 1000px) {
    .add-credits__header {
        font-size: 1.8rem;
        text-align: center;
    }

    .add-credits__p {
        width: 100%;
    }

    .add-credits__header {
        margin: 0 0 1rem 0;
    }
}